import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService {
    constructor(http) {
        this.http = http;
    }
    loginAction(form) {
        const formGroup = {
            username: form.username,
            password: form.password
        };
        return this.http.post('auth/login', formGroup);
    }
    recoverPasswordEmail(info) {
        return this.http.post(`${environment.apiUrl}user/request_password_recovery`, info);
    }
    resetPasswordMatch(passwords, username, token) {
        return this.http.post(`${environment.apiUrl}user/reset_password`, { username, token, password: passwords });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
