<div fxLayout="row" class="large-version">
  <div fxFlex="43" class="bg-container">
    <div class="text-container">
      <div class="logo"></div>
      <h2 style="padding-right: 34px">Welcome to Load Genie</h2>
      <p class="font-weight-bold">Americas Fastest Load Matching Technology</p>
    </div>
  </div>

  <div
    fxFlex="57"
    fxLayout="column"
    fxLayoutAlign="center center"
    class="email-recover-container"
    [formGroup]="form"
  >
    <div></div>
    <div style="width: 305px">
      <h1 style="font-weight: 300; z-index: 1" class="dark-gray">
        RESTORE <span style="color: #025bb8">PASSWORD</span>
      </h1>
      <div class="mb-2">
        <span class="label dark-gray">New password</span>
      </div>
      <mat-form-field
        style="width: 100%; z-index: 1"
        appearance="outline"
        [class.input-error]="
          (form.get('password1').errors &&
            (form.get('password1').dirty || form.get('password1').touched)) ||
          (form.valid &&
            form.get('password1').value !== form.get('password2').value)
        "
      >
        <span matPrefix>
          <mat-icon style="color: darkgray">vpn_key</mat-icon>
        </span>
        <input
          matInput
          type="password"
          placeholder="Password"
          formControlName="password1"
        />
      </mat-form-field>
      <div
        *ngIf="
          form.get('password1').invalid &&
          (form.get('password1').dirty || form.get('password1').touched)
        "
        style="
          margin-top: -25px;
          margin-bottom: 20px;
          height: 64px;
          width: 100%;
          z-index: 1;
        "
        class="error-msg"
      >
        <div class="alert alert-danger">
          <div *ngIf="form.get('password1').errors.required">
            Password is required.
          </div>
          <div *ngIf="form.get('password1').errors.minLength">
            Password must have at least 8 digits
          </div>
          <div *ngIf="form.get('password1').errors.pattern">
            Password must contain 1 upper case, 1 lower case, 1 number, 1
            special char, and at min 8 digits.
          </div>
        </div>
      </div>

      <div class="mb-2">
        <span class="label dark-gray">Confirm password</span>
      </div>
      <mat-form-field
        style="width: 100%; z-index: 1"
        appearance="outline"
        [class.input-error]="
          (form.get('password2').errors &&
            (form.get('password2').dirty || form.get('password2').touched)) ||
          (form.valid &&
            form.get('password1').value !== form.get('password2').value)
        "
      >
        <span matPrefix>
          <mat-icon style="color: darkgray">vpn_key</mat-icon>
        </span>
        <input
          matInput
          type="password"
          placeholder="Password"
          formControlName="password2"
        />
      </mat-form-field>
      <div
        *ngIf="
          form.get('password2').invalid &&
          (form.get('password2').dirty || form.get('password2').touched)
        "
        style="
          margin-top: -25px;
          margin-bottom: 20px;
          height: 64px;
          width: 100%;
          z-index: 1;
        "
        class="error-msg"
      >
        <div class="alert alert-danger">
          <div *ngIf="form.get('password2').errors.required">
            Password is required.
          </div>
          <div *ngIf="form.get('password2').errors.minLength">
            Password must have at least 8 digits
          </div>
          <div *ngIf="form.get('password2').errors.pattern">
            Password must contain 1 upper case, 1 lower case, 1 number, 1
            special char, and at min 8 digits.
          </div>
        </div>
      </div>
      <div
        *ngIf="
          form.valid &&
          form.get('password1').value !== form.get('password2').value
        "
        style="margin-top: -19px; height: 30px; width: 80%"
      >
        <div class="error-msg">Passwords must match</div>
      </div>

      <div
        style="width: 100%; margin-top: 20px"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <button
          mat-button
          style="background-color: #025bb8; color: white"
          (click)="recoverPassword()"
        >
          Restore
        </button>
      </div>
    </div>

    <div
      class="brand-container"
      fxLayout="row"
      style="width: 305px; margin-top: 5em"
      fxLayoutAlign="space-between center"
    >
      <div style="margin-top: -12px" class="font-weight-bold">
        Terms , {{ date | date: "yyyy" }}
      </div>
      <div fxFlex></div>
      <div fxLayout="row">
        <div style="margin-right: 10px; margin-top: -2px">Support by</div>
        <div class="logo-bottom"></div>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" class="small-version">
  <div class="bg-container">
    <div class="text-container">
      <div class="logo"></div>
      <h2 style="padding-right: 34px">Welcome to Load Genie</h2>
      <p class="font-weight-bold phrase">
        Americas Fastest Load Matching Technology
      </p>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="mx-auto w-100"
      [formGroup]="form"
    >
      <div></div>
      <div class="form-restore">
        <h1 style="font-weight: 300; z-index: 1" class="dark-gray">
          RESTORE <span style="color: #025bb8">PASSWORD</span>
        </h1>
        <div class="mb-2">
          <span class="label dark-gray">New password</span>
        </div>
        <mat-form-field
          style="width: 100%; z-index: 1"
          appearance="outline"
          [class.input-error]="
            (form.get('password1').errors &&
              (form.get('password1').dirty || form.get('password1').touched)) ||
            (form.valid &&
              form.get('password1').value !== form.get('password2').value)
          "
        >
          <span matPrefix>
            <mat-icon style="color: darkgray">vpn_key</mat-icon>
          </span>
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password1"
          />
        </mat-form-field>
        <div
          *ngIf="
            form.get('password1').invalid &&
            (form.get('password1').dirty || form.get('password1').touched)
          "
          style="margin-top: -25px; width: 100%"
          class="error-msg"
        >
          <div class="alert alert-danger">
            <div *ngIf="form.get('password1').errors.required">
              Password is required.
            </div>
            <div *ngIf="form.get('password1').errors.minLength">
              Password must have at least 8 digits
            </div>
            <div *ngIf="form.get('password1').errors.pattern">
              Password must contain 1 upper case, 1 lower case, 1 number, 1
              special char, and at min 8 digits.
            </div>
          </div>
        </div>

        <div class="mb-2">
          <span class="label dark-gray">Confirm password</span>
        </div>
        <mat-form-field
          style="width: 100%; z-index: 1"
          appearance="outline"
          [class.input-error]="
            (form.get('password2').errors &&
              (form.get('password2').dirty || form.get('password2').touched)) ||
            (form.valid &&
              form.get('password1').value !== form.get('password2').value)
          "
        >
          <span matPrefix>
            <mat-icon style="color: darkgray">vpn_key</mat-icon>
          </span>
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password2"
          />
        </mat-form-field>
        <div
          *ngIf="
            form.get('password2').invalid &&
            (form.get('password2').dirty || form.get('password2').touched)
          "
          style="margin-top: -25px; width: 100%"
          class="error-msg"
        >
          <div class="alert alert-danger">
            <div *ngIf="form.get('password2').errors.required">
              Password is required.
            </div>
            <div *ngIf="form.get('password2').errors.minLength">
              Password must have at least 8 digits
            </div>
            <div *ngIf="form.get('password2').errors.pattern">
              Password must contain 1 upper case, 1 lower case, 1 number, 1
              special char, and at min 8 digits.
            </div>
          </div>
        </div>
        <div
          *ngIf="
            form.valid &&
            form.get('password1').value !== form.get('password2').value
          "
          style="margin-top: -19px; height: 30px; width: 80%"
        >
          <div class="error-msg">Passwords must match</div>
        </div>

        <div
          style="width: 100%; margin-top: 20px"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            mat-button
            style="background-color: #025bb8; color: white"
            (click)="recoverPassword()"
          >
            Restore
          </button>
        </div>
      </div>
    </div>
    <div
      class="brand-container"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div style="margin-top: -12px" class="font-weight-bold">
        Terms , {{ date | date: "yyyy" }}
      </div>
      <div fxFlex></div>
      <div fxLayout="row">
        <div style="margin-right: 10px; margin-top: -2px">Support by</div>
        <div class="logo-bottom"></div>
      </div>
    </div>
  </div>
</div>
