<div fxLayout="row" class="large-version">
  <div fxFlex="70" class="bg-container">
    <div class="text-container">
      <div class="logo"></div>
      <h2
        style="
          padding-right: 34px;
          font-size: 38px;
          letter-spacing: 1px;
          margin-block: 0px;
        "
      >
        Welcome to Load Genie
      </h2>
      <p
        class="font-weight-bold"
        style="font-size: 24px; letter-spacing: 0.8px"
      >
        Americas Fastest Load Matching Technology
      </p>
    </div>
  </div>

  <form
    fxFlex="30"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    class="login-container"
    [formGroup]="form"
    (ngSubmit)="goTo()"
  >
    <div></div>
    <div style="width: 65%">
      <h1 style="font-weight: 300; color: #4b4b4b">
        LOGIN <span style="color: #025bb8">TO</span> <br />
        <span style="color: #025bb8">YOUR ACCOUNT</span>
      </h1>
      <div>
        <div class="mb-2">
          <span class="label">Username</span>
        </div>
        <mat-form-field
          appearance="outline"
          [class.input-error]="
            form.get('username').errors &&
            (form.get('username').dirty || form.get('username').touched)
          "
          style="width: 100%; margin-top: -15px; color: darkgray"
        >
          <span matPrefix>
            <mat-icon>person</mat-icon>
          </span>
          <input
            matInput
            type="email"
            autocomplete="off"
            maxlength="250"
            formControlName="username"
            placeholder="Username"
            appPhone
            appDisableAutofill
          />
        </mat-form-field>
        <div style="margin-top: -39px; margin-bottom: 20px; margin-left: 2px">
          <div
            *ngIf="
              form.get('username').invalid &&
              (form.get('username').dirty || form.get('username').touched)
            "
            class="alert alert-danger error-msg"
          >
            <div *ngIf="form.get('username').errors.required">
              Username is required.
            </div>
            <div *ngIf="form.get('username').errors.minlength">
              Name must be at least 3 characters long.
            </div>
            <div
              *ngIf="
                form.get('username').errors.email ||
                form.get('username').errors.pattern
              "
            >
              Is not a valid email
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: -20px">
        <div class="mb-2">
          <span class="label">Password</span>
        </div>
        <mat-form-field
          style="width: 100%; margin-top: -15px; color: darkgray"
          appearance="outline"
        >
          <span matPrefix>
            <mat-icon>vpn_key</mat-icon>
          </span>
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password"
          />
        </mat-form-field>
        <div style="margin-top: -39px; margin-bottom: 20px; margin-left: 2px">
          <div
            *ngIf="
              form.get('password').invalid &&
              (form.get('password').dirty || form.get('password').touched)
            "
            class="alert alert-danger error-msg"
          >
            <div *ngIf="form.get('password').errors.required">
              Password is required.
            </div>
            <div *ngIf="form.get('password').errors.minlength">
              Password must be at least 6 characters long.
            </div>
            <div *ngIf="form.get('password').errors.pattern">
              Password not allowed.
            </div>
          </div>
        </div>
      </div>

      <!--  <div *ngIf="incorrect" class="alert alert-danger" style="color: gray">
        Incorrect user information
      </div> -->

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="label"
          style="z-index: 99"
        >
          <section class="example-section">
            <mat-checkbox class="example-margin" formControlName="rememberMe"
              >Remember Me</mat-checkbox
            >
          </section>
          <span (click)="openDialog()" style="cursor: pointer"
            >Forgot Password?</span
          >
        </div>

        <button
          mat-button
          type="submit"
          style="background-color: #025bb8; color: white; margin-top: 20px"
          [disabled]="!form.valid"
        >
          Login
        </button>
      </div>
    </div>

    <div
      class="brand-container"
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
    >
      <div style="margin-top: -12px; font-weight: bold">
        Terms , {{ date | date: "yyyy" }}
      </div>
      <div style="width: 100px"></div>
      <div fxLayout="row">
        <div style="margin-right: 10px">Support by</div>
        <div class="logo-bottom" style="margin-top: -2px"></div>
      </div>
    </div>
  </form>
</div>

<div fxLayout="row" class="small-version">
  <div class="bg-container">
    <div class="text-container">
      <div class="logo"></div>
      <h2 style="padding-right: 34px">Welcome to Load Genie</h2>
      <p class="font-weight-bold phrase">
        Americas Fastest Load Matching Technology
      </p>
    </div>
    <div class="mx-auto w-100" [formGroup]="form">
      <div></div>
      <div class="form-login">
        <h1 style="font-weight: 300; color: #4b4b4b" class="mt-0">
          LOGIN <span style="color: #025bb8">TO</span> <br />
          <span style="color: #025bb8">YOUR ACCOUNT</span>
        </h1>
        <div>
          <div class="mb-2">
            <span class="label">Username</span>
          </div>
          <mat-form-field
            appearance="outline"
            [class.input-error]="
              form.get('username').errors &&
              (form.get('username').dirty || form.get('username').touched)
            "
            style="width: 100%; margin-top: -15px; color: darkgray"
          >
            <span matPrefix>
              <mat-icon>person</mat-icon>
            </span>
            <input
              matInput
              type="email"
              autocomplete="off"
              maxlength="250"
              formControlName="username"
              placeholder="Username"
              appPhone
              appDisableAutofill
            />
          </mat-form-field>
          <div style="margin-top: -39px; margin-bottom: 20px; margin-left: 2px">
            <div
              *ngIf="
                form.get('username').invalid &&
                (form.get('username').dirty || form.get('username').touched)
              "
              class="alert alert-danger error-msg"
            >
              <div *ngIf="form.get('username').errors.required">
                Username is required.
              </div>
              <div *ngIf="form.get('username').errors.minlength">
                Name must be at least 3 characters long.
              </div>
              <div
                *ngIf="
                  form.get('username').errors.email ||
                  form.get('username').errors.pattern
                "
              >
                Is not a valid email
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: -20px">
          <div class="mb-2">
            <span class="label">Password</span>
          </div>
          <mat-form-field
            style="width: 100%; margin-top: -15px; color: darkgray"
            appearance="outline"
          >
            <span matPrefix>
              <mat-icon>vpn_key</mat-icon>
            </span>
            <input
              matInput
              type="password"
              placeholder="Password"
              formControlName="password"
            />
          </mat-form-field>
          <div style="margin-top: -39px; margin-bottom: 20px; margin-left: 2px">
            <div
              *ngIf="
                form.get('password').invalid &&
                (form.get('password').dirty || form.get('password').touched)
              "
              class="alert alert-danger error-msg"
            >
              <div *ngIf="form.get('password').errors.required">
                Password is required.
              </div>
              <div *ngIf="form.get('password').errors.minlength">
                Password must be at least 6 characters long.
              </div>
              <div *ngIf="form.get('password').errors.pattern">
                Password not allowed.
              </div>
            </div>
          </div>
        </div>

        <!--  <div *ngIf="incorrect" class="alert alert-danger" style="color: gray">
        Incorrect user information
      </div> -->

        <div
          fxLayout="column"
          fxLayoutAlign.xs="center stretch"
          fxLayoutAlign="space-between stretch"
          class="options-form"
        >
          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-between center"
            fxLayoutAlign.xs="center center"
            fxLayout.xs="column"
            class="label"
            style="z-index: 99"
          >
            <section class="example-section">
              <mat-checkbox class="example-margin" formControlName="rememberMe"
                >Remember Me</mat-checkbox
              >
            </section>
            <span
              class="forgot-pass"
              (click)="openDialog()"
              style="cursor: pointer"
              >Forgot Password?</span
            >
          </div>

          <button
            mat-button
            style="background-color: #025bb8; color: white; margin-top: 20px"
            [disabled]="!form.valid"
            (click)="goTo()"
          >
            Login
          </button>
        </div>
      </div>
    </div>
    <div
      class="brand-container"
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
    >
      <div style="margin-top: -12px; font-weight: bold">
        Terms , {{ date | date: "yyyy" }}
      </div>
      <div fxFlex></div>
      <div fxLayout="row">
        <div style="margin-right: 10px">Support by</div>
        <div class="logo-bottom" style="margin-top: -2px"></div>
      </div>
    </div>
  </div>
</div>
