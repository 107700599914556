import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient
  ) { }

  loginAction(form): Observable<any> {
    const formGroup = {
      username: form.username,
      password: form.password
    };
    return this.http.post('auth/login', formGroup);
  }

  recoverPasswordEmail(info): Observable<any> {
    return this.http.post(`${environment.apiUrl}user/request_password_recovery`, info);
  }

  resetPasswordMatch(passwords, username, token): Observable<any> {
    return this.http.post(`${environment.apiUrl}user/reset_password`, { username, token, password: passwords });
  }
}
