<div fxLayout="column" fxLayoutAlign="center center" [formGroup]="form">
  <div class="dark-gray" class="header-reset-pass">
    <h1 class="font-weight-normal" style="font-size: 26px">Forgot password</h1>
    <span style="font-size: 15px"
      >Enter your email address and we'll send you an email to reset your
      password</span
    >
  </div>

  <div style="margin-top: 20px; max-width: 370px; width: 100%">
    <div class="mb-2">
      <span class="label dark-gray">Email</span>
    </div>
    <mat-form-field
      appearance="outline"
      [class.input-error]="
        form.get('username').errors &&
        (form.get('username').dirty || form.get('username').touched)
      "
      style="margin-top: -15px; width: 100%"
    >
      <input
        matInput
        type="email"
        placeholder="Insert an email"
        formControlName="username"
        autocomplete="off"
        name="reset_username_new_way"
      />
    </mat-form-field>
    <div
      style="margin-top: -38px; margin-bottom: 20px; height: 30px"
      *ngIf="
        form.get('username').invalid &&
        (form.get('username').dirty || form.get('username').touched)
      "
    >
      <div class="alert error-msg">
        <div *ngIf="form.get('username').errors.required">
          Email is required.
        </div>
        <div *ngIf="form.get('username').errors.pattern">
          Is not a valid email
        </div>
      </div>
      <div *ngIf="sendEmail !== ''">
        {{ sendEmail }}
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around center">
    <button
      mat-button
      style="
        border: 1.5px solid #025bb8;
        color: #025bb8;
        background-color: white;
        min-width: 100px;
        margin-right: 7px;
      "
      (click)="closeModal()"
    >
      Cancel
    </button>
    <button
      mat-button
      style="
        background-color: #025bb8;
        color: white;
        min-width: 100px;
        margin-left: 7px;
      "
      (click)="recoverPassword()"
    >
      Recover
    </button>
  </div>
</div>
