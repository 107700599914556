import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../shared/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-password-recovery",
  templateUrl: "./password-recovery.component.html",
  styleUrls: ["./password-recovery.component.scss"],
})
export class PasswordRecoveryComponent implements OnInit {
  form = this.fb.group({});
  username = "";
  token = "";
  public date: Date = new Date();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.username = this.activatedRoute.snapshot.paramMap.get("username");
    this.token = this.activatedRoute.snapshot.paramMap.get("token");
  }

  createForm(): void {
    this.form = this.fb.group({
      password1: this.fb.control("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "^(?!.* )(?=.*[A-Z])(?=.*[!$%@#£€*?&])(?=.*[0-9])(?=.*[a-z]).{8,}$"
        ),
      ]),
      password2: this.fb.control("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "^(?!.* )(?=.*[A-Z])(?=.*[!$%@#£€*?&])(?=.*[0-9])(?=.*[a-z]).{8,}$"
        ),
      ]),
    });
  }

  matchPasswords(): boolean {
    return (
      this.form.get("password1").value === this.form.get("password2").value
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  recoverPassword(): void {
    if (this.form.valid && this.matchPasswords()) {
      this.authService
        .resetPasswordMatch(
          this.form.value.password1,
          this.username,
          this.token
        )
        .subscribe(
          (res) => {
            this.router.navigate(["/login"]);
            this.openSnackBar("Password changed successfully!");
          },
          (error: any) => {
            this.openSnackBar(error.error.message);
          }
        );
    }
  }
}
