import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../shared/services/auth.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { emailRegExp } from "../shared/models/Auth.model";

@Component({
  selector: "app-email-reset-password",
  templateUrl: "./email-reset-password.component.html",
  styleUrls: ["./email-reset-password.component.scss"],
})
export class EmailResetPasswordComponent implements OnInit {
  form = this.fb.group({});
  sendEmail = "";
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private matDialogRef: MatDialogRef<EmailResetPasswordComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.createEmailForm();
  }

  createEmailForm(): void {
    this.form = this.fb.group({
      username: this.fb.control("", [
        Validators.pattern(emailRegExp),
        Validators.required,
      ]),
    });
  }

  closeModal() {
    this.matDialogRef.close();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }

  recoverPassword(): void {
    if (this.form.valid) {
      this.authService.recoverPasswordEmail(this.form.value).subscribe(
        (res) => {
          this.matDialogRef.close();
          this.openSnackBar(
            "Email sent, Please check your email and follow instructions!"
          );
        },
        (error) => {
          this.matDialogRef.close();
          this.openSnackBar(error.error.message);
        }
      );
    }
  }
}
