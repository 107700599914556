import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import swal from "sweetalert2";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GeneralService {
    constructor(http) {
        this.http = http;
        GeneralService.instance = this;
    }
    post(params) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };
        return this.http
            .post(environment.apiUrl + this.actionUrl, params, httpOptions)
            .pipe(catchError(this.handleError));
    }
    // Error handling
    handleError(error) {
        console.log(error);
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = `Message: ${error.error.message}`;
            if (error.error.message == undefined ||
                typeof error.error.message == undefined) {
                errorMessage = "Oops, something went wrong, please try again";
            }
        }
        if (error.status === 404) {
            swal.fire("!Error!", "Error in model to save information", "error");
        }
        else if (error.status === 401) {
            swal.fire("Error!", "Auth invalid", "error");
        }
        else if (error.status !== 500 && error.status !== 0) {
            swal.fire("Error saving information!", error.error.message, "error");
        }
        else {
            swal.fire("Error!", errorMessage, "error");
        }
        return throwError(errorMessage);
    }
}
GeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeneralService_Factory() { return new GeneralService(i0.ɵɵinject(i1.HttpClient)); }, token: GeneralService, providedIn: "root" });
