import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  public actionUrl: string;
  static instance: GeneralService;

  constructor(private http: HttpClient) {
    GeneralService.instance = this;
  }

  public post(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post(environment.apiUrl + this.actionUrl, params, httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Error handling
  private handleError(error) {
    console.log(error);

    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Message: ${error.error.message}`;
      if (
        error.error.message == undefined ||
        typeof error.error.message == undefined
      ) {
        errorMessage = "Oops, something went wrong, please try again";
      }
    }

    if (error.status === 404) {
      swal.fire("!Error!", "Error in model to save information", "error");
    } else if (error.status === 401) {
      swal.fire("Error!", "Auth invalid", "error");
    } else if (error.status !== 500 && error.status !== 0) {
      swal.fire("Error saving information!", error.error.message, "error");
    } else {
      swal.fire("Error!", errorMessage, "error");
    }

    return throwError(errorMessage);
  }
}
