import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GeneralService } from "../shared/services/general.service";
import { emailRegExp, IAuth } from "../shared/models/Auth.model";
import sweet from "sweetalert2";
import { environment } from "src/environments/environment";
import { EmailResetPasswordComponent } from "../email-reset-password/email-reset-password.component";
import { MatDialog } from "@angular/material/dialog";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public isInvalid = false;
  public form: FormGroup;
  public user = {
    username: null,
    password: null,
  };
  private loginInfo: IAuth = {
    expires_in: null,
    token: null,
    type: null,
    user: {
      company_id: null,
      enabled: null,
      id: null,
      last_name: null,
      name: null,
      password: null,
      photo: null,
      phone: null,
      username: null,
      shipper_type: null,
      role: {
        id: null,
        name: null,
      },
    },
  };
  public sendingData = false;
  public date: Date = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private service: GeneralService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ["", [Validators.required, Validators.pattern(emailRegExp)]],
      password: ["", Validators.required],
      rememberMe: [false],
    });
    this.getRemeberMe();
  }

  goTo(): void {
    this.sendingData = true;
    this.user = {
      username: this.form.get("username").value,
      password: this.form.get("password").value,
    };
    this.user.username = this.user.username.toLowerCase();
    this.setRememberMe();
    this.service.actionUrl = "auth/login";
    this.service.post(this.user).subscribe(
      (data: IAuth) => {
        this.sendingData = false;
        this.loginInfo = data;
        this.switchPortal();
      },
      (error) => {
        this.sendingData = false;
        sweet.fire("Error", error, "error");
      }
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EmailResetPasswordComponent, {
      width: "600px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  switchPortal(): void {
    switch (this.loginInfo.user.role.name) {
      case "company_admin":
      case "customer":
      case "customer_admin":
        document.location.href = `${
          environment.domainCustomerPortal
        }?identity=${btoa(this.loginInfo.token)}&alivesession=${
          this.form.get("rememberMe").value
        }`;
        break;
      case "driver":
      case "carrier":
        document.location.href = `${
          environment.domainCarrierPortal
        }?identity=${btoa(this.loginInfo.token)}&alivesession=${
          this.form.get("rememberMe").value
        }`;
        break;
      case "super_admin":
      case "fronter":
      case "sales_rep":
      case "sales_manager":
      case "dispatcher":
      case "track_trace":
      case "account_payable":
      case "account_receivable":
      case "carrier_relations":
      case "data_entry":
      case "quality_assurance":
      case "carrier_sales":
        document.location.href = `${
          environment.domainOperatorPortal
        }?identity=${btoa(this.loginInfo.token)}&alivesession=${
          this.form.get("rememberMe").value
        }`;
        break;
      default:
        break;
    }
  }

  getRemeberMe() {
    const rememberMe = localStorage.getItem(
      environment.rememberMeLocalStorageItemName
    );
    if (rememberMe) {
      const bytes = CryptoJS.AES.decrypt(
        rememberMe,
        environment.rememberMeSecretKey
      );
      this.form.get("username").patchValue(bytes.toString(CryptoJS.enc.Utf8));
      this.form.get("rememberMe").patchValue(true);
    }
  }

  setRememberMe() {
    if (this.form.get("rememberMe").value && this.user.username != "") {
      const cipherText = CryptoJS.AES.encrypt(
        this.user.username,
        environment.rememberMeSecretKey
      ).toString();
      localStorage.setItem(
        environment.rememberMeLocalStorageItemName,
        cipherText
      );
    } else {
      localStorage.removeItem(environment.rememberMeLocalStorageItemName);
    }
  }
}
