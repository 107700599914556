export interface IAuth {
  expires_in: string;
  token: string;
  type: string;
  user: IUser;
}

export interface IUser {
  company_id: number;
  enabled: boolean;
  id: number;
  last_name: string;
  name: string;
  password: string;
  photo: string;
  phone: string;
  username: string;
  shipper_type?: string;
  role: IRol;
}

export interface IRol {
  id: number;
  name: string;
}

export const emailRegExp =
  '[a-zA-Z0-9.-_-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}';